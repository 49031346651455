import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Post from "../components/post"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

const ImageCard = styled.div`
  border: 1px solid black;
  width: 400px;
  position: relative;
  height: 500px;
  display: flex;
  justify-content: center;
  margin: 25px;
`

const Content = styled.div`
  display: flex;
  justify-content: space-around;
`

const Sidebar = styled.div`
  display: flex;
`

const Photography = ({ data, location }) => {
  return (
    <Layout location={location} title="Title">
      <Seo title="Photography" />
      <Content>
        <ImageCard>
          <StaticImage
            formats={["AUTO"]}
            src="../images/red.jpeg"
            width={500}
            height={900}
            quality={100}
            alt="img"
          />
        </ImageCard>
        <ImageCard>
          <StaticImage
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../images/red_ii.jpeg"
            width={500}
            height={900}
            quality={100}
            alt="img"
          />
        </ImageCard>
        <ImageCard>
          <StaticImage
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../images/red_iii.jpeg"
            width={500}
            height={900}
            quality={100}
            alt="img"
          />
        </ImageCard>
      </Content>
    </Layout>
  )
}

export default Photography
